/**
 * In this field create the toggle constants, should match Posthog Feature Flags
 *
 * Using spaces between to minimize git conflicts
**/
export const clientShowDev = 'client-show-dev'

export const clientShowHousekeepingNav = 'client-show-housekeeping-nav'

export const clientShowPaymentTransfer = 'client-show-payment-transfer'

export const clientShowOneTimePayment = 'client-show-one-time-payment'

export const clientShowProcessingFees = 'client-show-processing-fees'
